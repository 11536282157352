<section>
    <div data-aos="fade-up" data-aos-duration="500">
        <h3 class="section-title">
            {{"Projects.Title" | translate}}
        </h3>
    </div>
    <div>
        <ul class="project-categories mb-30 ml-15">
            <li *ngFor='let tag of "Projects.Tags" | translate; let i = index'
                data-aos="fade-up" data-aos-duration="500" [attr.data-aos-delay]="i*100"
                (click)="filterProjects(tag)" [class.active]="selectedTag === tag">
                {{tag || 'All' | titlecase}}
            </li>
        </ul>
    </div>
    <div id="projects-carousel" class="carousel" (scroll)="onScroll($event)">
        <div class="carousel-item-spacer"></div>
        <div @exit-animation *ngFor="let project of filtered; let i = index" class="carousel-item"
             data-aos="fade-up" data-aos-duration="500" data-aos-offset="-25"
             id="project-{{i}}" (click)="scroll(i, 'right')">
            <div>
                <div class="header">
                    <div class="title">{{project['Title']}}</div>
                    <div class="external-links">
                        <img *ngIf="project['CompanyLogo']" class="project-img" alt="CompanyLogo"
                             [src]="project['CompanyLogo']">
                        <a *ngIf="project['GithubLink']" [href]="project['GithubLink']" target="_blank">
                            <i class="external-link-btn fab fa-github" id="ghLink"></i>
                        </a>
                        <a *ngIf="project['Link']" [href]="project['Link']" target="_blank">
                            <i class="external-link-btn fas fa-external-link-alt"></i>
                        </a>
                    </div>
                </div>
                <p class="description">
                    {{project['Description']}}
                </p>
            </div>
            <div *ngIf="project['Tech']">
                 <span *ngFor="let technology of project['Tech']" class="tech">
                    <span class="highlight">{{technology}}</span>
                </span>
            </div>
            <div class="tech-icons" *ngIf="project['TechIcons']">
                <i *ngFor="let icon of project['TechIcons']" class="{{icon}}"></i>
            </div>
        </div>
        <div class="carousel-item-spacer"></div>
    </div>
    <p class="scroll-indicator" data-aos="fade-up" data-aos-duration="500" data-aos-offset="-50"
       *ngIf="filtered.length && !scrolled" @exit-animation (click)="scroll(0, 'right')">
        <i class="fas fa-mouse"></i> &nbsp; <span class="right">>></span>
    </p>
</section>


<section id="highlights" class="w-90v mnh-600 mb-160">
    <div class="content">
        <div class="title" data-aos="fade-up" data-aos-duration="500">
            <h3 class="section-title">
                {{"Highlights.Title" | translate}}
            </h3>
        </div>
        <div class="highlights">
            <div>
                <ul class="qualifications-list" data-aos="fade-up">
                    <li *ngFor="let text of 'Highlights.Qualifications' | translate; let i = index"
                        [innerHTML]="text"
                        class="qualifications-element bullet"
                        data-aos="fade-up" data-aos-duration="500" data-aos-offset="-50">
                    </li>
                </ul>
            </div>
            <div>
                <div *ngFor="let skill of 'Highlights.Skills' | translate; let i = index" class="skills mb-15"
                     data-aos="fade-up" data-aos-duration="500" data-aos-offset="-50">
                    <div><b>{{skill['Category']}}</b></div>
                    <span *ngFor="let s of skill['Elements']" class="skill-element">{{s}}</span>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="t-wrapper" [class.fullscreen]="fullscreen" data-aos="fade-up" data-aos-duration="500">
    <div class="t-container"
         (click)="inputActive()" cdkDrag cdkDragBoundary=".t-wrapper"
         [style.transform]="'translate3d(calc(50vw - 50%), calc(50vh - 50%), 0)'">
        <div class="t-menu" cdkDragHandle>
            <div class="t-buttons">
                <a (click)="navigate('home')">
                    <div class="t-button t-button-red"></div>
                </a>
                <div class="t-button t-button-yellow" (click)="clearTerminal()"></div>
                <div class="t-button t-button-green" (click)="fullscreen = !fullscreen"></div>
            </div>
            <div class="t-title">
                <a href="https://github.com/vararun" target="_blank"><i class='fab fa-github'></i> ~/arun-varghese</a>
            </div>
        </div>
        <div id="terminal">
            <li><br></li>
            <li *ngFor="let line of lines; let i = index" [innerHTML]="line"></li>
            <li *ngIf="waitForInput">$ <input id="terminal-input" autocomplete="off"></li>
            <li class="loading" *ngIf="loading"><br>loading</li>
            <li><br></li>
            <li id="anchor"><br></li>
        </div>
    </div>
</div>

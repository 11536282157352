<section class="contact mnh-40v mt-300">
    <div data-aos="fade-up" data-aos-duration="500">
        <h3 class="contact-pre-title">{{"Contact.Pretitle" | translate}}</h3>
        <h4 class="contact-title">
            {{"Contact.Title" | translate}}
        </h4>
    </div>
    <div class="contact-btn" data-aos="fade-up" data-aos-delay="100" data-aos-duration="500">
        <a class="main-btn" data-aos="fade-up" data-aos-duration="500"
           (click)="ga.sendAnalyticEvent('send-email', 'contact', 'click')"
           href="mailto:{{'Email' | translate}}"
           target='_blank'>
            {{"Contact.Btn" | translate}}
        </a>
    </div>
</section>

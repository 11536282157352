<section id="about" class="w-90v">
    <div class="profile-img w-160 h-160 mb-25" [style.transform]="'translate3d(0, 0, 0)'"
         data-aos="zoom-out" data-aos-duration="1000" cdkDrag cdkDragHandle (cdkDragEnded)="dragEnded($event)"></div>
    <div data-aos="fade-right" data-aos-duration="500">
        <h1 class="title">{{"About.Pretitle" | translate}}</h1>
    </div>
    <div class="about-title">
        <h2 class="name" data-aos="fade-right" data-aos-duration="500" data-aos-delay="100">
            {{"About.Name" | translate}}
        </h2>
        <h3 class="role" data-aos="fade-right" data-aos-duration="500" data-aos-delay="200">
            <span class="code-highlight" (click)="themeService.switchTheme()">{{role}}<span class="blinking-cursor">|</span></span>
        </h3>
    </div>
    <div class='about-description' data-aos="fade-right" data-aos-duration="500" data-aos-delay="300">
        <p [innerHTML]='"About.Description" | translate' class="mxw-500"></p>
    </div>
    <ul class="social-icons" data-aos="fade-right" data-aos-duration="500" data-aos-delay="400">
        <li *ngFor='let item of "SocialIcons" | translate; let i = index'>
            <a href="{{item['Link'] | translate}}" target='_blank'>
                <i class="{{item['Icon']}}"></i>
            </a>
        </li>
    </ul>
    <div class="download-resume" data-aos="fade-right" data-aos-duration="500" data-aos-delay="500" data-aos-offset="-50">
        <a (click)="analyticsService.sendAnalyticEvent('view-resume', 'about', 'click'); downloadResume()"
           class="main-btn" data-aos="fade-right" data-aos-duration="500">
            {{"About.ResumeBtn" | translate}}
        </a>
    </div>
</section>

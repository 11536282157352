<div class="cube-loader">
    <div class="cube-face cube-face-front profile">
    </div>
    <div class="cube-face cube-face-back">
        <i class="fab fa-npm"></i>
    </div>
    <div class="cube-face cube-face-left">
        <i class="fab fa-docker"></i>
    </div>
    <div class="cube-face cube-face-right">
        <i class="fab fa-angular"></i>
    </div>
    <div class="cube-face cube-face-bottom">
        <i class="fab fa-github"></i>
    </div>
    <div class="cube-face cube-face-top">
        <i class="fab fa-java"></i>
    </div>
</div>
